<template>
  <b-modal
    id="reject-withdraw-modal"
    :title="`${$t('utils.status.reject')} - ${$t('routes.VIEW_TRANSACTION_REQUEST')}`"
    hide-footer
  >
  <ValidationObserver
    ref="rejectWithdrawForm"
    v-slot="{ handleSubmit }"
  >
    <form @submit.prevent="handleSubmit(onSubmit)">
      <b-form-group
        label-cols-sm="12"
        label-cols-lg="3"
        label-for="isRefund"
      >
        <b-input-group>
          <b-form-checkbox
            v-model="rejectForm.isRefund"
            name="isRefund"
          >
          คืนเงิน
          </b-form-checkbox>
        </b-input-group>
      </b-form-group>
      <ValidationProvider
        v-slot="{ errors }"
        name="remark"
        :rules="!rejectForm.isRefund ? 'required' : null"
      >
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="`หมายเหตุ ${!rejectForm.isRefund?'':'*'}`"
          label-for="remark"
        >
          <b-form-input
            id="remark"
            v-model="rejectForm.remark"
            :state="errors[0] ? false : null"
          ></b-form-input>
        </b-form-group>
      </ValidationProvider>
      
      <div class="text-right">
        <b-button
          variant="light"
          class="mr-2"
          @click="$bvModal.hide('eject-withdraw-modal')"
        >
          {{ $t('buttons.cancel')}}
        </b-button>
        <b-overlay
          :show="isLoading"
          rounded
          spinner-small
          class="d-inline-block"
        >
          <b-button
            type="submit"
            variant="primary"
            block
          >
            {{ $t('buttons.confirm')}}
          </b-button>
        </b-overlay>
      </div>
    </form>
  </ValidationObserver>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'RejectWithdrawModal',
  props: {
    txnId: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      rejectForm: {
        isRefund: true,
        remark: '',
      },
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.withdraw.isRejectingWithdraw,
    }),
  },
  methods: {
    ...mapActions(['rejectWithdraw']),
    closeModal() {
      this.$bvModal.hide('reject-withdraw-modal')
      this.rejectForm = {
        isRefund: true,
        remark: '',
      }
    },
    async onSubmit() {
        await this.rejectWithdraw({
          id: this.txnId, 
          data: this.rejectForm
        })
        this.closeModal()
    },
  },
}
</script>
